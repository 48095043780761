.Contact-us-con{
    height: 100vh;
    background-image: url("../../assets/let's_talk.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
}

.Contact-us-con-main-container{
    display: grid;
    grid-template-columns: 540px 300px;
    justify-content: flex-start;
    background-color:transparent;
    height: 508px;
    margin-left: 160px;
}

.Contact-us-con-main-container-left{
    background-color: rgba(255, 255, 255, 0.15);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    border-radius: 10PX;
    
}

#Contact-us-title-one{
    color:white;
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#contact-us-line{
    width: 200px;
    height: 5px;
    background-color:white;
    margin-Top:10px;
    margin-bottom: 35px;
}

#name-filed{
    background-color: rgba(255, 255, 255, 0.75);
    width: 350px;
    height: 40px;
    font-size: medium;
    padding-left:5px ;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
}

#email-filed{
    background-color: rgba(255, 255, 255, 0.75);
    width: 350px;
    height: 40px;
    font-size: medium;
    padding-left:5px ;
    border-radius: 5px;
    margin-bottom: 10px;
}

#message-filed{
    background-color: rgba(255, 255, 255, 0.75);
    width: 350px;
    height: 100px;
    font-size: medium;
    padding-left:5px ;
    border-radius: 5px;
    margin-bottom: 10px;
}

.contact-submit-button{
    width: 350px;
    height: 46px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid white;
    font-size: 20px;
    color: white;
    margin-top: 20px;
}

#Contact-us-title-two{
    color:white;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    
}

@media screen and (max-width: 440px){
    
    .Contact-us-con-main-container{
        display: grid;
        grid-template-columns: 1fr;
        justify-content: flex-start;
        background-color:transparent;
        height: 508px;
        margin-left: 0px;
        
        
    }

    .Contact-us-con{
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/let's_talk.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; 
    }

    .Contact-us-con-main-container-left{
        background-color: rgba(255, 255, 255, 0.15);
        padding-left: 10px;
        padding-top: 10px;
        border-radius: 10PX;
        
    }

    .Contact-us-con{
        height: 100vh;
        background-image: url("../../assets/let's_talk.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 10px; 
    }
}




@media screen and (max-width: 850px){
    
    .Contact-us-con-main-container{
        display: grid;
        grid-template-columns: 1fr;
        justify-content: flex-start;
        background-color:transparent;
        height: 508px;
        margin-left: 0px;
        
        
    }

    .Contact-us-con{
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/let's_talk.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; 
    }

    .Contact-us-con-main-container-left{
        background-color: rgba(255, 255, 255, 0.15);
        padding-left: 10px;
        padding-top: 10px;
        border-radius: 10PX;
        
    }

    .Contact-us-con{
        height: 100vh;
        background-image: url("../../assets/let's_talk.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 10px; 
    }
}


