.Header-con {
    display: flex;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    height: 80px;
    backdrop-filter: blur(3px);
}

.Header-main-con {
    display: grid;
    grid-template-columns: 200px 600px 200px;
    justify-content: space-between;
    width: 100%;
    margin-left: 160px;
    margin-right: 160px;
    background-color: transparent;
    height: 80px;
}

.Header-links,
.Header-login {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header-logo {
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo-image {
    width: 161px;
}

.Header-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
}

.Header-links.active > .nav-bar-link-style {
    color: white;
}

.nav-bar-link-style {
    position: relative;
    color: inherit;
    text-decoration: none;
    color: white;
    font-size: 18px;
    padding: 5px 10px;
    overflow: hidden;
}

.nav-bar-link-style::before {
    content: " ";
    margin-top: 4px;
    width: 100%;
    height: 3px;
    transform: translate(-120%, 22px);
    background-color: white;
    transform-origin: left;
    transition: 0.6s;
    position: absolute;
}

.nav-bar-link-style:hover {
    color: #e8bf88;
    cursor: pointer;
}



.nav-bar-link-style:hover::before {
    transform: translate(-10px, 22px);
}

#mobile {
    display: none;
}

#bar {
    color: white;
    align-items: center;
    
}

@media screen and (max-width: 850px) {
    .Header-con {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        height: 80px;
        
    }

    #Header-links-con {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        top: 60px;
        right: -250px;
        width: 250px;
        height: 85vh;
        background-color: rgba(0, 0, 0, 0.8);
        padding-left: 10px;
        padding-top: 20px;
        font-family: var(--font-fam-two);
        font-size: 2em;
        display: none;
        
    }

    .Header-main-con {
        
        margin-left: 10px;
        
    }

    #Header-links-con.active {
        right: 0px;
        margin-top: 20px;
        display: flex;
    }

    #mobile {
        display: block;
        margin-top: 20px;
        position: fixed;
        right: 20px;
        
    }

    #bar {
        color: white;
        align-items: center;
        cursor: pointer;
        font-size: 2.5em;
        margin-right: 10;
        
        
        
    }

    .Header-login {
        display: none;
    }

    .Header-links.active .nav-bar-link-style {
        color: white;
    }
}

@media screen and (max-width: 440px) {
    .Header-con {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        height: 60px;
        
    }

    .logo-image {
        width: 120px;
    }

    .Header-login {
        display: none;
    }

    #Header-links-con {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        top: 60px;
        right: -250px;
        width: 250px;
        height: 85vh;
        background-color: rgba(0, 0, 0, 0.8);
        padding-left: 10px;
        padding-top: 20px;
        font-family: var(--font-fam-two);
        font-size: 2em;
        display: none;
    }

    #Header-links-con.active {
        right: 0px;
        display: flex;
    }

    #mobile {
        display: block;
        margin-top: 20px;
        position: fixed;
        right: 20;
        
    }

    #bar {
        color: white;
        align-items: center;
        cursor: pointer;
        font-size: 2.5em;
    }
}