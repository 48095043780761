.project-component-con {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-component-grid {
    display: grid;
    grid-template-rows: 60px 500px;
    row-gap: 20px;
}

.project-component-select-con {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.select-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border: 1px solid rgb(5, 5, 5);
   
}

.select-title-first {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border: 1px solid rgb(5, 5, 5);
    border-radius: 16px 0px 0px 16px;
    
}

.select-title-last {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border: 1px solid rgb(5, 5, 5);
    border-radius: 0px 16px 16px 0px;
    
}

.select-title:hover {
    
    color: white;
    background-color: #253847;
    transition: color 0.5s, background-color 0.5s;
}

.select-title-first:hover {
    color: white;
    background-color: #253847;
    transition: color 0.5s, background-color 0.5s;
}

.select-title-last:hover {
    color: white;
    background-color: #253847;
    transition: color 0.5s, background-color 0.5s;
}

.select-title-last{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border: 1px solid rgb(5, 5, 5);
    border-radius: 0px 16px 16px 0px;
}

#project-name{
    
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    line-height: normal;
    color: #000;
    margin-bottom: 5px;
    

}

.project-component-main-con{
    display: grid;
    grid-template-columns: 300px 300px;
    row-gap: 20px;
    column-gap: 20px;
    padding: 10px;
}

.project-component{
    padding: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.select-title,
.select-title-first,
.select-title-last {
  /* Your existing styles for the unselected titles */
  cursor: pointer;
}

.selected {
  background-color: black;
  color: white;
   /* Set your desired background color here */
  /* Add any additional styles for the selected element */
}


@media screen and (max-width: 440px){

    .project-component-main-con{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        column-gap: 20px;
        padding: 10px;
        overflow: scroll;
        height: auto;
        margin-left: 0px;
    }

    .project-component-grid {
        display: grid;
        grid-template-rows: 60px 500px;
        row-gap: 20px;
    }

    .project-component{
        padding: 10px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .select-title-first {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 32px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #000;
        border: 1px solid rgb(5, 5, 5);
        border-radius: 16px 0px 0px 16px;
        
    }

    .select-title-last{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 32px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #000;
        border: 1px solid rgb(5, 5, 5);
        border-radius: 0px 16px 16px 0px;
    }

    .select-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 32px;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #000;
        border: 1px solid rgb(5, 5, 5);
       
    }

   
    
    
}