.footer-main-con{
    
    height: 218px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px); 
}
.footer-main{
    display: grid;
    grid-template-rows: 180px 38px;
    
}

.footer-main-container-raw-2{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 35px;
    background-color: transparent;
    margin-left: 80px;
    margin-right: 80px;
    border-top: 1px solid rgba(255, 255, 255, 0.45);
    border-bottom: 1px solid rgba(255, 255, 255, 0.45);
}

.footer-main-container{
    display: grid;
    grid-template-columns: 400px 200px 200px 200px;
    justify-content: space-between;
    margin-left: 160px;
    margin-right: 160px;
    background-color:transparent;
    height: 200px;
}

.footer-main-container-one{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    height: 218px;
}

.footer-main-container-two{
   padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    height: 218px;
}

#footer-title{
    color:white;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-main-container-address{
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer-info{
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-left: 10px;
}

.footer-main-container-socialmedia{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.footer-two-links{
    display: flex;
    flex-direction: column;
    margin-top: 14px;
}

#footer-links{
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: lighter;
    margin-bottom: 10px;
}

#footer-copyrights{
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: lighter;
    
}


@media screen and (max-width: 850px) {
    .footer-main-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        background-color: transparent;
        height: auto;
    }

    .footer-main-con {
        height: auto;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
        padding-bottom: 45px;
    }

    .footer-main-container-raw-2 {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 45px;
    }
}

@media screen and (max-width: 440px) {
    .footer-main-container {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        background-color: transparent;
        height: auto;
    }

    .footer-main-con {
        height: 900px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
    }

    .footer-main-container-raw-2 {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 550px;
    }
}

