.Projects-con{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    
}

.Projects-main-con{
    display: grid;
    grid-template-columns: 400px 700px;
    column-gap: 100px;
    height: 620px;
    margin-left: 160px;
    margin-right: 160px;
    
      
}

.Projects-main-con-right,
.Projects-main-con-left{
    background-color: transparent;
}

.Projects-main-con-left{
    display: grid;
    grid-template-rows: 300px 300px;
    row-gap: 20px;
}

.Projects-main-con-left-row-2,
.Projects-main-con-left-row-1{
    background-color: transparent;
}

#Projects-title{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#project-line{
    width: 200px;
    height: 5px;
    background-color:black;
    margin-Top:10px;
    margin-bottom: 35px;
    
}

#Projects-title-two{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Projects-main-con-left-row-2{
    display: grid;
    grid-template-columns: 200px 200px;
    
}

.Projects-main-con-left-row-2-col{
    display: flex;
    background-color: transparent;
    justify-content: center;
}

.total-projects{
    height: 100px;
    width: 170px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#total-projects-number{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-style: bold;
    font-weight: 500;
    line-height: normal;
}

.total-projects-title{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Projects-main-con-right{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Projects-main-con-right-row-2{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 440px){

    .Projects-con{
        width: 1fr;
        height: 200vh;
        background-color: transparent;
        display: flex;
        justify-content: center;
        overflow: hidden;
        
        
        
        
    }

    .Projects-main-con{
        
        display: grid;
        grid-template-columns: 1fr; /* Set column width to screen */
        grid-template-rows: 620px 620px ;
        justify-content: center;
        background-color: transparent;
        height: auto;
        width: auto;
        margin-top: auto;
    }

    .Projects-main-con-left{
        display: grid;
        grid-template-rows: 300px 300px;
        row-gap: 20px;
        
        
    }

    .Projects-main-con-left-row-2,
.Projects-main-con-left-row-1{
    background-color: transparent;
    padding: 10px;
    padding-left: 20px;
    
}

.Projects-main-con-left-row-1{
    background-color: transparent;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
    
}

#Projects-title-two{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
    
}

.Projects-main-con-left-row-2{
    display: grid;
    grid-template-columns: 200px;
    
}



    
}




@media screen and (max-width: 850px){

    .Projects-con{
        width: 1fr;
        height: 200vh;
        background-color: transparent;
        display: flex;
        justify-content: center;
        overflow: hidden;
        margin-top: 5%;
        
        
        
        
    }

    .Projects-main-con{
        
        display: grid;
        grid-template-columns: 1fr; /* Set column width to screen */
        grid-template-rows: 620px 620px ;
        justify-content: center;
        background-color: transparent;
        height: auto;
        width: auto;
        margin-top: auto;
    }

    .Projects-main-con-left{
        display: grid;
        grid-template-rows: 300px 300px;
        row-gap: 20px;
        
    }

    .Projects-main-con-left-row-2,
.Projects-main-con-left-row-1{
    background-color: transparent;
    padding: 10px;
    
}

#Projects-title-two{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
    
}

.Projects-main-con-left-row-2{
    display: grid;
    grid-template-columns: 200px 200px;
    column-gap: 20px;
    
}

.Projects-main-con-right{
    margin-top: -250px;
    margin-top: -50%;
}



    
}