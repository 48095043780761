.MoreAboutUs-main-com{
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.MoreAboutUs-main-com-body-one{
    height: 88vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-left: 160px;
    margin-right: 160px;
    margin-top: 50px;
}

.MoreAboutUs-main-com-body-one-title{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: white;
    margin-bottom: 40px;
}

.MoreAboutUs-main-com-body-one-content{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    color: white;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
}

.MoreAboutUs-main-com-body-one-images-one{
    height: 380px;
    width: 600px;
    background-image: url("../../assets/more_about_us.jpg");
    background-size: cover;
}

.MoreAboutUs-main-com-body-two{
    height: 100vh;
    margin-left: 160px;
    margin-right: 160px;
}

.MoreAboutUs-main-com-body-two-main{
    display: grid;
    grid-template-columns: 450px 500px;
    column-gap: 20px;
    height: 100vh;
    justify-content: space-between;
}

.MoreAboutUs-main-com-body-two-main-left{
    background-color: transparent;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.our-story{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color:black;

    
}

.MoreAboutUs-main-com-body-two-main-right{
    background-color:transparent;
    padding-top: 40px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.MoreAboutUs-main-com-body-two-main-right-p1,
.MoreAboutUs-main-com-body-two-main-left-p1{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 40px;
    
}


.MoreAboutUs-main-com-body-two-main-right-p1{
    margin-top: 0px;
    font-size: 1rem;
}

.MoreAboutUs-main-com-body-three{
    align-items: center;
    margin-left: 160px;
    margin-right: 160px;
}

.MoreAboutUs-main-com-body-three-main-title{
    padding-top: 40px;
    padding-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color:black;
}

.MoreAboutUs-main-com-body-three-main-team{
    display: grid;
    grid-template-rows: 350px 350px ;
    row-gap: 20px;
    margin-top: 30px;
    place-content: center;
    
}

.team-member{
    
    width: 900px;
    display: grid;
    grid-template-columns: 250px 600px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 10px;
}

.team-member-name{
    
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-top: 10px;

}

.team-member-description{
    padding: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    color: #000000;
    margin-top: 10px;
    
    
}



@media screen and (max-width: 850px){

    .MoreAboutUs-main-com-body-one{
        height: 88vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        margin-left: 160px;
        margin-right: 160px;
        margin-top: 50px;
    }

    .MoreAboutUs-main-com-body-one-content{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: white;
        margin-bottom: 40px;
        margin-left: 40px;
        margin-right: 40px;
        text-align: center;
    }
    
    .MoreAboutUs-main-com-body-two{
        height: 100vh;
        margin-left: 160px;
        margin-right: 160px;
    }
    
    .MoreAboutUs-main-com-body-two-main{
        display: grid;
        grid-template-columns: 450px ;
        column-gap: 20px;
        height: 100vh;
        justify-content: space-between;
        /* margin-bottom: 100px; */
    }

    .MoreAboutUs-main-com-body-three{
        align-items: center;
        margin-left: 160px;
        margin-right: 160px;
        margin-top: 75px;
        height: auto;
        /* margin-bottom: 100px; */
    }
    
    .MoreAboutUs-main-com-body-three-main-title{
        padding-top: 40px;
        padding-left: 10px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color:black;
    }
    
    .MoreAboutUs-main-com-body-three-main-team{
        display: grid;
        grid-template-rows: 350px  ;
        row-gap: 20px;
        margin-top: 30px;
        place-content: center;
        
    }
    .team-member-description{
        padding: 5px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        color: #000000;
        margin-top: 10px;
        /* display: none; */
        
    }

    .team-member{
    
        width: 900px;
        display: grid;
        grid-template-columns: 200px 350px ;
        column-gap: 30px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin-bottom: 10px;
        height: auto;
       
    }

    .MoreAboutUs-main-com-body-one-images-one{
        height: 200px;
        width: 300px;
        background-image: url("../../assets/more_about_us.jpg");
        background-size: cover;
    }

    
    
    
}

@media screen and (max-width: 440px){

    .MoreAboutUs-main-com-body-one{
        height: 88vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
    }

    .MoreAboutUs-main-com-body-one-content{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: white;
        margin-bottom: 40px;
        margin-left: 40px;
        margin-right: 40px;
        text-align: center;
    }
    
    .MoreAboutUs-main-com-body-two{
        height: 850px;
        margin-left: 20px;
        margin-right: 20px;
        
    }
    
    .MoreAboutUs-main-com-body-two-main{
        display: grid;
        grid-template-columns: 350px ;
        column-gap: 20px;
        justify-content: space-between;
        margin-bottom: 100px;
        
    }

    .MoreAboutUs-main-com-body-three{
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 75px;
        height: auto;
        /* margin-bottom: 100px; */
    }
    
    .MoreAboutUs-main-com-body-three-main-title{
        padding-top: 40px;
        padding-left: 10px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color:black;
    }
    
    .MoreAboutUs-main-com-body-three-main-team{
        display: grid;
        grid-template-rows: 350px  ;
        row-gap: 20px;
        margin-top: 30px;
        place-content: center;
        
    }
    .team-member-description{
        padding: 5px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        color: #000000;
        margin-top: 10px;
        display: none;
        
    }

    .team-member{
    
        width: 340px;
        display: grid;
        grid-template-columns: 200px;
        column-gap: 10px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin-bottom: 10px;
        height: auto;
       
    }

    .MoreAboutUs-main-com-body-one-images-one{
        height: 200px;
        width: 300px;
        background-image: url("../../assets/more_about_us.jpg");
        background-size: cover;
    }

    
    
    
}

