.service-page-con{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
}

.service-con-main-container{
    display: grid;
    grid-template-columns: 970px 348px;
    column-gap: 100px;
    height: 672px;
    
}

.service-con-main-container-left{
    background-color: transparent;
    margin-left: 160px;
    display: grid;
    grid-template-rows: 336px 336px;

}

.service-con-main-container-right{
    background-image: url("../../assets/Services.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    
}


.service-con-main-container-left-row-1,
.service-con-main-container-left-row-2{
    background-color: transparent;
    display: grid;
    grid-template-columns: 260px 260px 260px;
    justify-content: space-between;
    align-items: center;
}

.service-con-main-container-left-row-1-col-1{
    background-color: transparent;
    height: 310px;
}

.service-con-main-container-left-row-1-col-2{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    height: 310px;
    
}

.image-box{
    display: grid;
    grid-template-rows: 100px 160px;
    row-gap: 10px;
    padding: 5px;

}

.service-con-main-container-left-row-2-col-1{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    height: 310px;
}

#service-title{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#Service-line{
    width: 180px;
    height: 5px;
    background-color:black;
    margin-Top:10px;
    margin-bottom: 35px;
}

#service-sub-title{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    
    
}

.service-topic{
    margin-bottom: 10px;
}

#service-sub-title-one{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: normal;
    
    
}

.service-image{
    width: 100px;
    height: 100px;
    background-color: transparent;
    margin: 0 auto; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}

#service-sub-title-two{
    color: rgba(0, 0, 0, 0.60);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: lighter;
    
}

@media screen and (max-width: 440px){

    .service-page-con{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .service-con-main-container-right{
        display: none;
    }


.service-con-main-container{
    display: grid;
    grid-template-columns: 370px ;
    height: auto;
    
    
}

.service-con-main-container-left{
    background-color: transparent;
    margin-left: 0px;
    display: grid;
    grid-template-rows: 1050px;
    

}

.service-con-main-container-left-row-1 {
    background-color: transparent;
    display: grid;
    grid-template-columns: 260px;
    grid-template-rows: 336px 336px 336px;
    row-gap: 20px;
    justify-content: space-around;
    align-items: center;
    height: auto;
   
}

.service-con-main-container-left-row-2 {
    background-color: transparent;
    display: grid;
    grid-template-columns: 260px;
    grid-template-rows: 336px 336px 336px;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    height: auto;

    
}

}



@media screen and (max-width: 850px){

    .service-page-con{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-top: -18%;
    }

    .service-con-main-container-right{
        display: none;
    }


.service-con-main-container{
    display: grid;
    grid-template-columns: 370px ;
    height: auto;
    
    
}

.service-con-main-container-left{
    background-color: transparent;
    margin-left: 0px;
    display: grid;
    grid-template-rows: 1050px;
    

}

.service-con-main-container-left-row-1 {
    background-color: transparent;
    display: grid;
    grid-template-columns: 260px;
    grid-template-rows: 336px 336px 336px;
    row-gap: 20px;
    justify-content: space-around;
    align-items: center;
    height: auto;
   
}

.service-con-main-container-left-row-2 {
    background-color: transparent;
    display: grid;
    grid-template-columns: 260px;
    grid-template-rows: 336px 336px 336px;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    height: auto;

    
}

}