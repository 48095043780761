.About-us-con{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.About-us-main-con{

    display: grid;
    grid-template-columns: 760px 440px;
    column-gap: 100px;
    height: 486px;
    
}


.About-us-main-con-left{
    
    height: 486px;
    background-image: url("../../assets/About_Us.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
}

.About-us-main-con-right{
    background-color: transparent;
    height: 486px;
    display: grid;
    grid-template-rows: 242px 242px;
    row-gap: 2px;   
}

.About-us-main-con-right-row-one{
    background-color: transparent;
    display: grid;
    grid-template-columns: 314px 126px;   
}

.About-us-main-con-right-row-two{
    background-color:transparent;
}

.About-us-main-con-right-row-one-title{
    background-color: transparent;
}

#About-us-title{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#About-us-line{
    width: 200px;
    height: 5px;
    background-color:black;
    margin-Top:10px;
    margin-bottom: 35px;
    
}

#About-us-title-two{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 2vw;
    font-style: normal;
    font-weight: lighter;
}

#water-mark{
    color: rgba(0, 0, 0, 0.10);
    font-family: 'Roboto', sans-serif;
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    writing-mode: vertical-rl; 
    transform: rotate(180deg); 
}

#About-us-title-three{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: lighter;
    
}

.About-us-main-con-right-row-one-image{
    background-color:transparent;
}

.About-us-con-Botton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 44px;
    background-color: #253847;
    border-radius: 6px;
    margin-top: 20px;
}

#About-us-button{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




/* Mobile responsive */

@media screen and (max-width: 440px){

    .About-us-main-con-left{
        display: none;
    }

    .About-us-main-con{

        display: grid;
        grid-template-columns: 330px;
        column-gap: 100px;
        height: 486px;
        margin-left: 10px;
        
    }

    .About-us-main-con-right-row-one{
        background-color: transparent;
        display: grid;
        grid-template-columns: 200px 126px;   
        
    }

    #About-us-title-two{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 5vw;
        font-style: normal;
        font-weight: lighter;
    }

    #About-us-title-three{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: lighter;   
    }

    .About-us-main-con-right-row-two{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
}




/* Desktop responsive */

@media screen and (max-width: 850px){

    .About-us-main-con-left{
        display: none;
    }



    .About-us-main-con{

        display: grid;
        grid-template-columns: 400px;
        column-gap: 100px;
        height: 100%;
        margin-top: 100px;
        /* margin-left: 250px; */
        grid-template-columns: 1fr;
        margin-left: 25px;
        margin-right: 25px;
        /* margin-bottom: 25px; */
        
    }

    .About-us-con{
    height: 100vh; /* Example: set a height for the parent container (viewport height) */
    display: flex;
    align-items: center;

    }

    .About-us-main-con-right-row-one{
        background-color: transparent;
        display: grid;
        grid-template-columns: 200px 126px; 
        /* background-color: #253847;   */
        
    }

    #About-us-title{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    #About-us-title-two{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: lighter;
    }

    #About-us-title-three{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: lighter;   
    }

    .About-us-main-con-right-row-two{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
}