*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    
    
}

.Homapage-con {
    height: 100vh;
    background-image: url("../../assets/Landing_Page_Background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    overflow: hidden;
    
}

.Homepage-main-con
    {
    display: grid;
    grid-template-columns: 160px 695px;
    justify-content: flex-start;
    background-color:transparent;
    height: 600px;
}

.Homepage-main-con-left{
    background-color: transparent;
}

.Homepage-main-con-left-image{
    margin-top: 210px;
}

.Homepage-main-con-right{
    display: grid;
    grid-template-rows: 50px 180px 100px 100px;
    background-color: transparent;
    align-items: center; 
    margin-top: 100px;
}

#title-one{
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    color: white;
}

#title-two-part-one,
#title-two-part-three{
    font-size: 64px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: white;
}

#title-two-part-two{
    font-size: 64px;
    font-family:'Road Rage', cursive;
    font-weight: 900;
    color: #E8BF88;
}

#title-three-part-one,
#title-three-part-three{
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: white;
}

#title-three-part-two{
    font-size: 18px;
    font-family:'Roboto', sans-serif;
    color: white;
    font-weight: 700;
}

.Homepage-main-con-right-four-Botton{
    margin-top: -50px;
    width: 160px;
    height: 44px;
    border: 1px solid white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    cursor: pointer;
}

.Homepage-main-con-right-four-Botton:hover {
    transform: scale(1.1);
}

#title-four-part-one{
    
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: normal;
}






/* Mobile responsive */

@media screen and (max-width: 440px){
    .Homepage-main-con-left{
        display: none;
    }

    #title-one{
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        color: white;
    }

    #title-two-part-one,
    #title-two-part-three{
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: white;
}

#title-two-part-two{
    font-size: 28px;
    font-family:'Road Rage', cursive;
    font-weight: 900;
    color: #E8BF88;
}
.Homepage-main-con
    {
    display: grid;
    grid-template-columns:250px;
    justify-content: flex-start;
    background-color:transparent;
    overflow: hidden;
}
#title-three-part-one,
#title-three-part-three{
    font-size: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
}

#title-three-part-two{
    font-size: 12px;
    font-family:'Roboto', sans-serif;
    color: white;
    font-weight: 700;
}
.Homepage-main-con-right-four-Botton{
    margin-top: -30px;
    width: 120px;
    height: 34px;
    border: 1px solid white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#title-four-part-one{
    
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: normal;
}

.Homepage-main-con-right{
    display: grid;
    grid-template-rows: 30px 100px 70px 100px;
    background-color: transparent;
    align-items: center; 
    margin-top: 40px;
}
.Homepage-con {
    width: 1fr;
    height: auto;
    background-image: url("../../assets/Landing_Page_Background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}
}

/* Desktop responsive */

@media screen and (max-width: 850px){
    .Homepage-main-con-left{
        display: none;
        
    }

    #title-one{
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        color: white;
        margin-left: 25px;
    }

    #title-two-part-one {
        font-size: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: white;
    margin-left: 25px;

    }
    #title-two-part-three{
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: white;
    /* margin-left: 25px; */
}

#title-two-part-two{
    font-size: 30px;
    font-family:'Road Rage', cursive;
    font-weight: 900;
    color: #E8BF88;
    margin-left: 25px;
}
.Homepage-main-con
    {
    display: grid;
    grid-template-columns:400px;
    justify-content: flex-start;
    background-color:transparent;
    overflow: hidden;
}
#title-three-part-one,
#title-three-part-three{
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    color: white;
    margin-left: 25px;
}

#title-three-part-two{
    font-size: 15px;
    font-family:'Roboto', sans-serif;
    color: white;
    font-weight: 700;
    
}
.Homepage-main-con-right-four-Botton{
    margin-top: -30px;
    width: 120px;
    height: 34px;
    border: 1px solid white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
}
#title-four-part-one{
    
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: normal;
}

.Homepage-main-con-right{
    display: grid;
    grid-template-rows: 30px 100px 70px 100px;
    background-color: transparent;
    align-items: center; 
    margin-top: 40px;
}
.Homepage-con {
    width: 1fr;
    height: auto;
    background-image: url("../../assets/Landing_Page_Background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}


}